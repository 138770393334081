(function ($) {
  $(document).ready(function () {
    // Send click events to Google Analytics based on data-track-event
    // <a href="/page/" data-track-event="category,action,label,value">...</a>

    $('a[data-track-event],p[data-track-event]').click(function (evt) {
      // don't use `this.href` because it will include the full link on named anchors
      var link = this.getAttribute('href');
      var target = this.getAttribute('target');
      // extract arguments from data attribute and push event to _gaq
      var args = this.getAttribute('data-track-event').split(',').map(function (s) {
        return s.trim();
      });

      _trackEvent.apply(null, args);
    });
  });
})(jQuery);
