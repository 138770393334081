/*
 * Trigger the newsletter modal after X pages visited in a session.
 * Info is stored in a cookie so the counter survives across page loads.
 * Submitting or closing the form will hide the modal for 30 days.
 */
(function ($) {

  // hide this selector when the modal pops-up (prevents ads loading over modal)
  var hideElementSelector = '#ad-lead';

  function getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
  }

  $(document).ready(function () {


    //
    // Modal selection
    //

    var selectedModalId = null;
    var urlParams = new URLSearchParams(window.location.search);
    var showParam = urlParams.get('show-modal');

    if (showParam) {
      selectedModalId = showParam;
      console.log('modal override: force show')
    } else {
      console.log('modal options', MODALOPTIONS);
      //
      // select random number 0 - max cdf weight
      // MODALOPTIONS format: (weight, id)
      maxWeight = Math.max.apply(null, MODALOPTIONS.map(function (x) {
        return x[0]
      }));
      var rval = getRandomArbitrary(0, maxWeight);

      for (var i = 0; i < MODALOPTIONS.length; i += 1) {
        if (MODALOPTIONS[i][0] >= rval) {
          selectedModalId = MODALOPTIONS[i][1];
          break;
        }
      }
    }


    if (selectedModalId) {
      console.log('modal selected: ', selectedModalId, '(r was ' + rval + ')');
      //ajax content request
      $.get('/newsletters/modal/' + selectedModalId + '/', {fromUrl: window.location.href}, function (response) {

        $('body').append(response);
        var $modal = $('#newsletter-modal');

        var modalId = selectedModalId;
        var $modalForm = $modal.find('form');

        //read in settings
        var displayAfterPageViews = $modal.data('display-delay');
        var displayModalNow = $modal.data('display-now');
        var suppressModalNow = $modal.data('suppress-now');
        var displayNumberOfTimes = $modal.data('display-number-of-times');
        var hideNumberOfDays = $modal.data('hide-number-of-days');

        // create new function which requires no arguments
        var showModal = _showModal.bind(undefined, $modal);

        // link from newsletter means they are already subscribed
        if (window.location.search.indexOf('mkt_tok=') !== -1) {
          hideModal();
        }

        if (suppressModalNow) {
          console.log('modal suppressed at this path');
          hideModal();
        } else {
          if (displayModalNow || showParam) {

            showModal();
            incrementModalPageCount();
            incrementModalDisplayCount();

          } else if (!isModalHidden()) {

            // check page count
            incrementModalPageCount();
            var pageCount = getModalPageCount();
            console.log('modal page count', pageCount, '(of ' + displayAfterPageViews + ' to view)');
            if (pageCount >= displayAfterPageViews) {
              console.log('modal has enough page views: ', pageCount);
              var displayCount = getModalDisplayCount();
              if (displayCount <= displayNumberOfTimes) {
                showModal();
                incrementModalDisplayCount();
                console.log('modal can be displayed ' + (displayNumberOfTimes - displayCount) + ' more times');
              } else {
                console.log('modal exceeded display count (' + displayNumberOfTimes + ')');
                hideModal();
                resetModalPageCount();
                resetModalDisplayCount();
              }
            }

          }
        }


        //
        // Modal event handlers
        //

        // hide modal close
        $modal.on('hide.bs.modal', function () {
          $(hideElementSelector).css('opacity', 100);
          hideModal();
          trackEvent($modal.data('modal-id'), 'closed');
        });


        $modalForm.submit(function () {
          hideModal();
          trackEvent($modal.data('modal-id'), 'submitted');
        });


        //
        // Util functions
        //

        function _showModal($modal) {
          $(hideElementSelector).css('opacity', 0);
          // break modal out of any fixed or relative positioning
          // http://stackoverflow.com/q/10636667/116042
          $modal.appendTo("body").modal('show');
          trackEvent($modal.data('modal-id'), 'displayed');
        }


        function incrementModalPageCount() {
          setModalPageCount(getModalPageCount() + 1)
        }

        function incrementModalDisplayCount() {
          setModalDisplayCount(getModalDisplayCount() + 1);
        }

        function hideModal() {
          console.log('hiding modal for ' + hideNumberOfDays + ' days');
          Cookies.set('NL-hidden-' + modalId, 1, {expires: hideNumberOfDays});
          setModalPageCount(0);
          setModalDisplayCount(0);
        }

        function isModalHidden() {
          var hiddenState = typeof Cookies.get('NL-hidden-' + modalId) !== 'undefined';
          console.log('modal hidden: ', hiddenState);
          return hiddenState;
        }

        function getModalPageCount() {
          var pageCount = Cookies.get('NL-pg-ct-' + modalId);
          return parseInt(pageCount ? pageCount : 0, 10);
        }

        function setModalPageCount(val) {
          Cookies.set('NL-pg-ct-' + modalId, val, {expires: 9999});
        }

        function resetModalPageCount() {
          setModalPageCount(0);
        }

        function getModalDisplayCount() {
          var displayCount = Cookies.get('NL-disp-ct-' + modalId);
          return parseInt(displayCount ? displayCount : 0, 10);
        }

        function setModalDisplayCount(val) {
          Cookies.set('NL-disp-ct-' + modalId, val, {expires: 9999});
        }

        function resetModalDisplayCount() {
          setModalDisplayCount(0);
        }

        function trackEvent(modalId, label) {
          window._gaq = window._gaq || [];
          _trackEvent('newsletter', 'modal_' + modalId, label);
        }

      });
    }
  });

})(jQuery);
