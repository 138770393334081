// replicate django-pipeline build

/* START Global variables */
	/*
	 * Media breakpoints copied from global.less
	 * Keep as integer to allow calculations, but remember to append px units
	 */
window.screen_xs = 320; // Phone
window.screen_sm = 768; // Tablet
window.screen_md = 1002; // Desktop
window.screen_lg = 1258; // HD Desktop

// Paths
window.path_imgs = 	'/graphics';
/* END Global variables */

//import "./v4/bootstrap.min.js";
import "./v4/jquery/jquery.detectmobilebrowsers.min.js";

import "./v4/newsletter.js";
import "./v4/analytics.js";
//import "./v4/swipe-nav.js";


// off-canvas open and close
// Mobile menu
$(document).ready(function() {
	// toggle mobile menu
	$('#mobileMenuButton, #mobileMenuBackdrop').on('click', function() {
		$('#mobileMenuButton').toggleClass('open');
		$('#mobileMenuBackdrop').toggleClass('open');
		$("#cssmenu").toggleClass('is-expanded');
		$("body").toggleClass('lock-scrolling');
	});

	// toggle user drawer
	$('#piano-login, #userMenuBackdrop, #close-user-menu').on('click', function() {
		$('.user-dropdown-drawer').toggleClass('is-expanded');
		$('#userMenuBackdrop').toggleClass('open');
		$("body").toggleClass('lock-scrolling');
	});

	// display/hide mobile submenus
	$("#cssmenu > ul > li > a:not(.hg-menu)").on('click', function(e) {
		let size = $(window).width();
		if( size < 800 ) {
			e.preventDefault();

			//regardless of where we are and what happens, toggle the submenu
			$(this).toggleClass('open-subs');
			$(this).parent('li').toggleClass('down');

		} else {
			return true;
		}
	});

});
